/* ---------- ellipsis ---------- */
.ellipsis {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: pretty;
}
.ellipsis.line-2 {
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

/* ---------- font weight ---------- */
.weight-100 {
  font-weight: var(--font-weight-100);
}
.weight-200 {
  font-weight: var(--font-weight-200);
}
.weight-300 {
  font-weight: var(--font-weight-300);
}
.weight-400 {
  font-weight: var(--font-weight-400);
}
.weight-500 {
  font-weight: var(--font-weight-500);
}
.weight-600 {
  font-weight: var(--font-weight-600);
}

/* ---------- line height ---------- */
.line-30 {
  line-height: 30px;
}

/* ---------- scrollbar ---------- */
.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: #fafafa;
  border-radius: 100px;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #c1c1c1;
}

/* ----------- wrapper ----------- */
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper-margin {
  margin: 60px 0;
}
.wrapper-margin-top {
  margin-top: 60px;
}
.wrapper-margin-bottom {
  margin-bottom: 60px;
}
.wrapper-padding {
  padding: 60px 0;
}
.wrapper-padding-top {
  padding-top: 60px;
}
.wrapper-padding-bottom {
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .wrapper-margin {
    margin: 40px 0;
  }
  .wrapper-margin-top {
    margin-top: 40px;
  }
  .wrapper-margin-bottom {
    margin-bottom: 40px;
  }
  .wrapper-padding {
    padding: 40px 0;
  }
  .wrapper-padding-top {
    padding-top: 40px;
  }
  .wrapper-padding-bottom {
    padding-bottom: 40px;
  }
}

/* ---------- container ---------- */
.container {
  width: var(--web-base-width);
  max-width: var(--web-base-maxwidth);
}
.container-margin {
  margin: 30px 0;
}
.container-margin-top {
  margin-top: 30px;
}
.container-margin-bottom {
  margin-bottom: 30px;
}
.container-padding {
  padding: 30px 0;
}
.container-padding-top {
  padding-top: 30px;
}
.container-padding-bottom {
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .container {
    width: var(--mobile-base-width);
  }
  .container-margin {
    margin: 20px 0;
  }
  .container-margin-top {
    margin-top: 20px;
  }
  .container-margin-bottom {
    margin-bottom: 20px;
  }
  .container-padding {
    padding: 20px 0;
  }
  .container-padding-top {
    padding-top: 20px;
  }
  .container-padding-bottom {
    padding-bottom: 20px;
  }
}
