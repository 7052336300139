.hamburgerMenu .hamburger-react div {
  height: 1.8px !important;
  width: 30px !important;
}
.hamburgerMenu .hamburger-react {
  width: 40px !important;
  position: relative;
  right: 2px;
  top: -4px;
  scale: 0.8;
}

.hamburgerMenu .hamburger-react div:nth-of-type(2) {
  width: 19px !important;
  left: 18px !important;
}
.hamburgerMenuOpen .hamburger-react {
  width: 40px !important;
  position: relative;
  right: -2px;
  top: -4px;
  scale: 0.8;
}
