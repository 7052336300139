.studio-theme {
  /* ---------- theme font ---------- */
  --title-font-family: "poppins";
  --text-font-family: "poppins";
  --default-font-family: "poppins";
  --title-banner-font-family: "Abhaya Libre";

  /* ---------- theme colors ---------- */
  --primary-color: #00c1cb;
  --primary-dark-color: #009097;
  --primary-light-color: #d8c7d8;
  --primary-thin-color: #fff4ff;

  --secondary-color: #e0a121;
}
